module.exports = {
    BASE_URL: "https://api.ziinlo.com/v1/",
    USER_CACHE_KEY: "zinlo_cache",
    SIDE_BAR_MENU_ITEM_KEY: "sideBarMenuItemKey",
    CREATE_PRODUCT: "product", 
    GOOGLE_AUTH_CLIENT_ID: "43796092749-aq1dc9tq96lcb48jvm93ke8pmlb2l1ia.apps.googleusercontent.com",
    GOOGLE_AUTH_CLEINT_SECRET: "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", 
    GOOGLE_ANALYTICS_ID: "G-S7GJKSZRFE", 
    PAYSTACK_KEY: "pk_live_37562527da97620c6ad69756176fe3c5c01cf69f", 
    PRICING: { // USD / month
        basic: 0, 
        standard: 8, 
        business: 15
    }
};
