<template>
  <div class="chat-bookmark">

  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.chat-bookmark {
  height: 100%;
  width: 100%;
  background-color: orange;
}
</style>