<template>
  <div class="chat-files">

  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.chat-files {
    height: 100%;
    width: 100%;
    background-color: orangered;
}
</style>